import React from 'react';

function App() {
  return (
    <>
      <div><img src={"https://www.cambro.tv/contents/videos_screenshots/596000/596329/preview.mp4.jpg"} style={{width: '100%'}} /></div>
    </>
  );
}

export default App;
